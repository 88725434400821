<template>
  <div class='template'>    
      <transition name="fade" appear>
      <article class="images">
          <div v-for="image in 12" :key="image" :class="[
            image === 6 ? 'title-slide' : 'info-tile', 
            statSlides.includes(image) ? 'stat-slide': '', 
            imageSlides.includes(image) ? 'image-slide': '', 
            isImageSlide(image) ? 'has-image' : '',
            isStatSlide(image) ? 'has-stat' : '']" 
            :style="getImageSlide(image)"> 
            <transition name="slide" appear>
                <div v-if="image === 6">
                    <div>
                        <h1>{{page.title}}</h1>
                        <AttachmentUI flipped :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="videoVisible = true"/>
                    </div>
                </div>
                <div v-else-if="statSlides.includes(image)" v-html="getSlide(image)"/>
                <div v-else-if="imageSlides.includes(image)"  />
                <div v-else />
            </transition>
          </div>              
      </article>        
      </transition>
     <div class="linear-holder">
        <LinearButton :to="page?.previous" previous/>
        <LinearButton :to="page?.next"/>
    </div> 
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>  
</template>
<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Infographic Tiled',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle
    },
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,             
            statSlides:[3,8,11,4,7,12],            
        }
    },
    computed: {  
        imageSlides() {
            if(this.stats.length === 4){
                return [2,5,7,9,10];
            } else if(this.stats.length >= 5){
                return [2,5,9,10];
            }
            return [2,4,5,7,9,10];
        },
        imagesFromGallery() {
            return this.imageSlides.length - 1
        },
        collage() {
            let images = [];
            if(this.page.background) {
                images.push({
                    file:this.page.background,
                    title:this.page.title
                });
            }            
            for (let index = 0; index < Math.min(this.imagesFromGallery, this.page.gallery?.length); index++) {
                const element = this.page.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },      
        gallery() {
            let images = [];        
            for (let index = this.imagesFromGallery; index < this.page.gallery?.length; index++) {
                const element = this.page?.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },
        copy() {
            let copy = this.page?.copy;            
            return copy.replace(/\[.*?\]/g, "");
        },
        stats() {
            let stats = this.parseShortCode(this.page?.copy);
            return stats.slice(0, 6);   //limits up to 6
        }
    },
    methods : {
        isImageSlide(slideIndex) {
            if(this.imageSlides.includes(slideIndex)) {
                const imageSlideIndex = this.imageSlides.indexOf(slideIndex);
                if(this.collage.length > imageSlideIndex) {
                    return true;
                }
            }
            return false;
        },
        isStatSlide(slideIndex) {
            if(this.statSlides.includes(slideIndex)) {
                const statSlideIndex = this.statSlides.indexOf(slideIndex);
                if( [4,7,12].includes(slideIndex) && this.stats.length > statSlideIndex) {
                    return true;
                }
            }
            return false;
        },
        getImageSlide(slideIndex){
            if(this.imageSlides.includes(slideIndex)) {
                const imageSlideIndex = this.imageSlides.indexOf(slideIndex);
                if(this.collage.length > imageSlideIndex) {
                    const collage = this.collage[imageSlideIndex];
                    if(!this.isStatSlide(slideIndex)) {
                        return {
                            backgroundImage:`url(${collage.file})`
                        };
                    }
                }
            }
        },
        getSlide(slideIndex){
            if(this.statSlides.includes(slideIndex)) {
                const statSlideIndex = this.statSlides.indexOf(slideIndex);
                if(this.stats.length > statSlideIndex) {
                    const stat = this.stats[statSlideIndex];                    
                    return `
                            <p class="value">${stat.value}<sup>${stat.symbol}</sup></p>
                            <p class="label">${stat.label}</p>
                    `;
                }
            }
            return "";
        },
        parseShortCode(shortCode) {
            const shortcodes = [];     
            const rawShortCodes = shortCode.match(/[\w-]+=".+?"/g);
            let elementsInGroup = 3;
            for (let index = 0; index < rawShortCodes?.length; index += elementsInGroup) {
                elementsInGroup = 3;
                const shortcode = {};
                const element1 = rawShortCodes[index].match(/([\w-]+)="(.+?)"/);
                const element2 = rawShortCodes[index + 1].match(/([\w-]+)="(.+?)"/);
                const element3 = rawShortCodes[index + 2]?.match(/([\w-]+)="(.+?)"/) ?? [];
                                
                shortcode[element1[1]] = element1[2];
                shortcode[element2[1]] = element2[2];
                if( element3[1] !== 'symbol') {
                    elementsInGroup = 2;
                    shortcode['symbol'] = '%';
                } else {
                    shortcode[element3[1]] = element3[2] === '-' ? '' : unescape(element3[2]);
                }
                
                shortcodes.push(shortcode);
            }            
            return shortcodes;
        }
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .linear-holder {
            position: relative;
            height:90px;
            width:100%;
        }
    }
    .template {
        display:grid;
        place-items: center;
        position: relative;
        background-size: cover;
        background-position: center;
        &:before {      
            content:"";
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background: rgba(18,18,18,0.8)
        }
    }            
    .images {
        position: relative;
        top:0;
        left:0;
        width:100%;
        //height:100%;
        display:grid;
        grid-template-columns: 1;
        grid-auto-rows: 300px;
        overflow: hidden;        
        @media (max-width:499px) {
            > div:first-child {
                display:none;
            }
        }
        @media (max-width:999px) {
            .title-slide {
                order:-1;
            }
        }
        @media (min-width:500px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width:850px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width:1000px) {
            grid-template-columns: repeat(4, 1fr);
            grid-auto-rows:unset;
            grid-template-rows: repeat(3, calc(100% / 3));
            height:calc(100vh - 70px);
        }
        > div {
            display:grid;
            place-items: center;
            padding:0 60px;
            box-sizing: border-box;
            text-align: right;     
            overflow: hidden;       
            .ui {
                justify-content:flex-end;
            }
        }
    }
    .info-tile {
        background:url('~@/assets/images/texture_black-asphalt.jpg');       
        background-size: cover;
        background-position: center; 
    }
    h1 {
        font-family: 'Helvetica Neue LT Std';
        font-weight: 900;  
        font-size: 2.125rem;
        position: relative;
        color: #F5F5F5;      
        border-bottom:1px solid #0066CC;
        text-transform: uppercase;
        padding:0;
        padding-bottom: 10px;
        line-height:1;
    }      
    .stat-slide {   
        background-size: cover !important;  
        background: linear-gradient(270deg, #161616 0%, #070707 100%); 
        &:last-child {
            background:url('~@/assets/images/texture_black-asphalt.jpg');    
        }      
        &.has-stat {
            background: linear-gradient(90deg, #F3F2F2 0%, #ABA9A9 100%);
            :deep() .value, :deep() .label {
                color: #181818;
            }
        }             
        > div {
            border-left:1px solid #F5F5F5;            
            padding:0 36px;
            width: 100%;
            box-sizing: border-box;
            position: relative;
            user-select: none;
            @media (hover:hover) {
                &:after {
                    content:"";
                    position: absolute;
                    top:0;
                    left:-1px;
                    width:2px;
                    height:0;
                    background:#0066CC;
                    transition: height 0.2s ease-in-out;
                }
                &:hover {
                    &:after {
                        height:100%;
                    }
                }
            }
        }
        p {
            margin:0;
        }
        :deep() .value {
            font-family: 'Helvetica Neue LT Std';
            font-weight: 900;
            color: #F5F5F5;
            font-size: 6rem;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 1;
            text-align: left;  
            margin:0;
            sup {
                font-size: 0.5em;
            }
        }
        :deep() .label {
            font-family: 'Helvetica Neue LT Std';
            font-weight: bold;
            text-transform: uppercase;
            color: #F5F5F5;
            font-size: 1rem;
            letter-spacing: 0;
            line-height: 1;
            text-align: left;  
        }
    }
    .image-slide {
        background:url('~@/assets/images/texture_black-asphalt.jpg');       
        background-size: cover;
        background-position: center; 
        &.has-image {
            &:not(.has-stat) {
                position: relative;
                &:before {            
                    content:"";
                    background: linear-gradient(270deg, rgba(18,18,18,0) 0%, rgba(18,18,18,0.7) 100%);
                    position:absolute;
                    top:0;
                    left:0;
                    bottom:0;
                    right:0;
                }
            }
        }
    }    
</style>